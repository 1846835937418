export const IS_PHASE_2 = false
export const EXTRA_LARGE_WINDOW_WIDTH = 1330
export const LARGE_WINDOW_WIDTH = 1024
export const TWO_LARGE_WINDOW_WIDTH = 1080
export const MEDIUM_WINDOW_WIDTH = 767
export const SMALL_WINDOW_WIDTH = 640
export const EXTRA_SMALL_WINDOW_WIDTH = 360

export const CAROUSEL_IMAGE_COUNT = 5

export const BADGE_SLIDER_SETTINGS_GLIDE = {
  showDots: false,
  rewind: false,
  animationDuration: 500,
  perView: 5.6,
  gap: 0,
  breakpoints: {
    1239: {
      perView: 4.6
    },
    979: {
      perView: 3.6
    },
    820: {
      perView: 2.6
    },
    [MEDIUM_WINDOW_WIDTH]: {
      perView: 4.6
    },
    450: {
      perView: 3.6
    },
    400: {
      perView: 2.6
    }
  }
}
export const GALLERY_SLIDER_SETTINGS = {
  showNavArrow: true,
  showDots: false,
  rewind: false,
  perView: 1,
  animationDuration: 500
}

export const SHORTS_GALLERY_GLIDE_SETTINGS = {
  gap: 0,
  showDots: true,
  showNavArrow: true,
  rewind: false,
  animationDuration: 500,
  perView: 5,
  peek: {
    before: 0,
    after: 100
  },
  breakpoints: {
    1920: {
      perView: 5,
      gap: 10,
      peek: {
        before: 0,
        after: 100
      }
    },
    1550: {
      perView: 4,
      peek: {
        before: 0,
        after: 120
      }
    },
    1400: {
      perView: 4,
      peek: {
        before: 0,
        after: 30
      }
    },
    1240: {
      perView: 3,
      peek: {
        before: 0,
        after: 100
      }
    },
    1100: {
      perView: 3,
      peek: {
        before: 0,
        after: 80
      }
    },
    1000: {
      perView: 3,
      peek: {
        before: 0,
        after: 30
      }
    },
    950: {
      perView: 2,
      peek: {
        before: 0,
        after: 220
      }
    },
    900: {
      perView: 2,
      peek: {
        before: 0,
        after: 150
      }
    },
    750: {
      perView: 2,
      peek: {
        before: 0,
        after: 50
      }
    },
    630: {
      perView: 1,
      peek: {
        before: 0,
        after: 220
      }
    },
    550: {
      perView: 1,
      peek: {
        before: 0,
        after: 150
      }
    },
    500: {
      perView: 1,
      peek: {
        before: 0,
        after: 110
      }
    },
    400: {
      perView: 1,
      peek: {
        before: 0,
        after: 50
      }
    }
  }
}

export const SLIDER_SETTINGS_CARS_LISTING = {
  speed: 500,
  showDots: false,
  fade: true,
  showNavArrow: true,
  rewind: false,
  gap: 0,
  ignorePerViewInControls: true
}

export const SLIDER_SETTINGS_CARS_LISTING_SECONDARY = {
  ...SLIDER_SETTINGS_CARS_LISTING,
  animationDuration: 500,
  perView: 6,
  gap: 16
}

export const SLIDER_AUTO_PLAY_SETTINGS = {
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true
}

export const SLIDER_SETTINGS_SINGLE = {
  showDots: true,
  showNavArrow: true,
  gap: 0,
  rewind: false,
  breakpoints: {
    [LARGE_WINDOW_WIDTH]: {
      showDots: true
    },
    [MEDIUM_WINDOW_WIDTH]: {
      showDots: false
    }
  }
}

export const SLIDER_SETTINGS_GLIDE = {
  showNavArrow: true,
  showDots: true,
  rewind: false,
  perView: 3,
  gap: 20,
  animationDuration: 500,
  breakpoints: {
    [LARGE_WINDOW_WIDTH]: {
      perView: 2
    },
    [SMALL_WINDOW_WIDTH]: {
      perView: 1
    }
  }
}

export const COMPARISON_SNAPSHOT_SETTINGS_GLIDE = {
  showNavArrow: true,
  rewind: false,
  perView: 2,
  gap: 0,
  animationDuration: 500,
  peek: {
    before: 0,
    after: 30
  },
  breakpoints: {
    [MEDIUM_WINDOW_WIDTH]: {
      perView: 1,
      peek: {
        before: 0,
        after: 0
      }
    }
  }
}

export const SLIDER_SETTINGS = {
  dots: true,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: LARGE_WINDOW_WIDTH,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: MEDIUM_WINDOW_WIDTH,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: SMALL_WINDOW_WIDTH,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: EXTRA_SMALL_WINDOW_WIDTH,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export const CENTER_SLIDER_SETTINGS = {
  dots: false,
  arrows: true,
  center: true,
  speed: 500,
  slidesToShow: 3,
  infinite: false,
  variableWidth: true,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: LARGE_WINDOW_WIDTH,
      settings: {
        slidesToShow: 2,
        variableWidth: true,
        slidesToScroll: 2,
        dots: false,
        infinite: false
      }
    },
    {
      breakpoint: MEDIUM_WINDOW_WIDTH,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false
      }
    },
    {
      breakpoint: SMALL_WINDOW_WIDTH,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false
      }
    },
    {
      breakpoint: EXTRA_SMALL_WINDOW_WIDTH,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false
      }
    }
  ]
}

export const SLIDER_SETTINGS_VIDEOS_VERTICAL = {
  className: 'vertical',
  vertical: true,
  verticalSwiping: true,
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  speed: 500,
  outline: false,
  responsive: [
    {
      breakpoint: MEDIUM_WINDOW_WIDTH,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: SMALL_WINDOW_WIDTH,
      settings: {
        slidesToShow: 3
      }
    }
  ]
}

export const SLIDER_SETTINGS_VIDEOS_BLOCK_VERTICAL = {
  className: 'vertical',
  vertical: true,
  gap: 0,
  verticalSwiping: true,
  dots: false,
  infinite: false,
  slidesToShow: 3.3,
  slidesToScroll: 1,
  arrows: true,
  speed: 500,
  outline: false,
  responsive: [
    {
      breakpoint: 1239,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: SMALL_WINDOW_WIDTH,
      settings: {
        slidesToShow: 4
      }
    }
  ]
}

export const SLIDER_SETTINGS_VERTICAL = {
  className: 'vertical',
  vertical: true,
  verticalSwiping: true,
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  speed: 500,
  outline: false,
  responsive: [
    {
      breakpoint: MEDIUM_WINDOW_WIDTH,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: SMALL_WINDOW_WIDTH,
      settings: {
        slidesToShow: 3
      }
    }
  ]
}

export const SEARCH_PER_PAGE = 12
export const SEARCH_ARTICLES_PER_PAGE = 6

export const SEARCH_FILTERS = [
  { label: 'News', value: 'news', name: 'news' },
  { label: 'Reviews', value: 'reviews', name: 'reviews' },
  { label: 'Opinion', value: 'opinion', name: 'opinion' },
  { label: 'Advice', value: 'advice', name: 'advice' }
]
// TODO to check with business later about the max no of models.
export const SHOWROOMS_MODELS_PER_PAGE = 20

export const SHOWROOMS_DEALS_PER_PAGE = 20

export const SHOWROOMS_CAR_TYPE_PATHNAME = '/showrooms/car-type/[slug]'

export const SHOWROOMS_MAKE_PATHNAME = '/showrooms/[make]'

export const SHOWROOMS_DEALS_PATHNAME = '/showrooms/deals'

export const RATINGS_CATEGORY = {
  efficiency: 'Energy Efficiency',
  fitness: 'Fit for Purpose',
  handling: 'Handling & Dynamics',
  infotainment: 'Infotainment & Connectivity',
  interior: 'Interior Comfort & Packaging',
  overall: 'Overall',
  performance: 'Performance',
  ride: 'Ride Quality',
  safety: 'Safety Technology',
  technology: 'Driver Technology',
  value: 'Value for Money'
}

export const SHOWROOM_TYPE_MAKE = 'make'
export const SHOWROOM_TYPE_MODEL = 'model'
export const SHOWROOM_TYPE_VARIANT = 'variant'

export const MOBILE_DEVICE_SIZES = ['small', 'medium', 'large']
export const TABLET_DEVICE_SIZES = ['medium', 'large']

export const HOMEPAGE_SLIDER_SETTINGS = {
  gap: 0,
  rewind: true,
  showNavArrow: true,
  showDots: true,
  perView: 1,
  rewindDuration: 0,
  autoplay: 7000,
  type: 'slider'
}

export const HOMEPAGE_DEALS_SLIDER_SETTINGS = {
  gap: 0,
  showDots: true,
  showNavArrow: true,
  rewind: false,
  animationDuration: 500,
  perView: 4,
  breakpoints: {
    1240: {
      perView: 3,
      peek: {
        before: 0,
        after: 100
      }
    },
    1100: {
      perView: 3,
      peek: {
        before: 0,
        after: 80
      }
    },
    1000: {
      perView: 3,
      peek: {
        before: 0,
        after: 30
      }
    },
    950: {
      perView: 2,
      peek: {
        before: 0,
        after: 120
      }
    },
    900: {
      perView: 2,
      peek: {
        before: 0,
        after: 80
      }
    },
    750: {
      perView: 2,
      peek: {
        before: 0,
        after: 50
      }
    },
    630: {
      perView: 1,
      peek: {
        before: 0,
        after: 190
      }
    },
    550: {
      perView: 1,
      peek: {
        before: 0,
        after: 120
      }
    },
    440: {
      perView: 1,
      peek: {
        before: 0,
        after: 100
      }
    },
    400: {
      perView: 1,
      peek: {
        before: 0,
        after: 50
      }
    }
  }
}

export const CARS_FOR_SALE_CARDS = 5

export const HOMPAGE_CARS_FOR_SALE_QUERIES = [
  {
    state: 'nsw',
    odometer: { lt: 5000 },
    imageCount: { gt: 0 }
  },
  {
    state: 'vic',
    odometer: { lt: 5000 },
    imageCount: { gt: 0 }
  },
  {
    state: 'qld',
    odometer: { lt: 5000 },
    imageCount: { gt: 0 }
  },
  {
    state: 'sa',
    odometer: { lt: 5000 },
    imageCount: { gt: 0 }
  },
  {
    state: 'wa',
    regionId: '36',
    odometer: { lt: 5000 },
    imageCount: { gt: 0 }
  }
]

export const HOMPAGE_FEATURED_SLIDER_MAX_SLIDES = 8

export const SHOWROOM_MODEL_POSTS_SLIDER_SETTINGS = {
  showDots: true,
  showNavArrow: true,
  rewind: false,
  gap: 16,
  animationDuration: 500,
  perView: 4,
  breakpoints: {
    1239: {
      perView: 3,
      peek: {
        before: 0,
        after: 50
      }
    },
    979: {
      perView: 2,
      peek: {
        before: 0,
        after: 94
      }
    },
    680: {
      perView: 1,
      peek: {
        before: 0,
        after: 170
      }
    },
    600: {
      perView: 1,
      peek: {
        before: 0,
        after: 150
      }
    },
    550: {
      perView: 1,
      peek: {
        before: 0,
        after: 90
      }
    },
    400: {
      gap: 16,
      perView: 1,
      peek: {
        before: 0,
        after: 32
      }
    }
  }
}

export const DRIVE_TESTIMONIALS_POSTS_SLIDER_SETTINGS = {
  showDots: true,
  showNavArrow: true,
  rewind: false,
  gap: 0,
  animationDuration: 500,
  perView: 4,
  breakpoints: {
    1239: {
      perView: 3,
      peek: {
        before: 0,
        after: 50
      }
    },
    979: {
      perView: 2,
      peek: {
        before: 0,
        after: 120
      }
    },
    680: {
      perView: 1,
      peek: {
        before: 0,
        after: 170
      }
    },
    600: {
      perView: 1,
      peek: {
        before: 0,
        after: 150
      }
    },
    550: {
      perView: 1,
      peek: {
        before: 0,
        after: 90
      }
    },
    400: {
      perView: 1,
      peek: {
        before: 0,
        after: 50
      }
    }
  }
}

export const LATEST_POST_BY_TAG_SLIDER_SETTINGS = (perView = 3) => ({
  showDots: true,
  showNavArrow: true,
  rewind: false,
  gap: 12,
  animationDuration: 500,
  perView: perView,
  breakpoints: {
    1239: {
      perView: perView - 1 !== 0 ? perView - 1 : 1,
      peek: {
        before: 0,
        after: 94
      }
    },
    680: {
      perView: perView - 2 !== 0 ? perView - 2 : 1,
      peek: {
        before: 0,
        after: 170
      }
    },
    600: {
      perView: perView - 2 !== 0 ? perView - 2 : 1,
      peek: {
        before: 0,
        after: 150
      }
    },
    550: {
      perView: 1,
      peek: {
        before: 0,
        after: 90
      }
    },
    400: {
      gap: 12,
      perView: 1,
      peek: {
        before: 0,
        after: 64
      }
    }
  }
})
export const SHOWROOM_TOP_MODELS_GLIDE_SETTINGS = {
  rewind: false,
  perView: 5,
  showNavArrow: true,
  showDots: true,
  gap: 20,
  animationDuration: 500,
  breakpoints: {
    1023: {
      perView: 4
    },
    979: {
      perView: 3
    },
    767: {
      perView: 1,
      peek: {
        before: 0,
        after: 110
      }
    }
  }
}

export const SHOWROOM_MODEL_RANGE_GLIDE_SETTINGS = {
  rewind: false,
  perView: 5,
  showNavArrow: true,
  showDots: true,
  gap: 24,
  animationDuration: 500,
  breakpoints: {
    1023: {
      perView: 1,
      gap: 0,
      peek: {
        before: 350,
        after: 350
      }
    },
    850: {
      perView: 1,
      gap: 0,
      peek: {
        before: 300,
        after: 300
      }
    },
    800: {
      perView: 1,
      gap: 0,
      peek: {
        before: 280,
        after: 280
      }
    },
    750: {
      perView: 1,
      gap: 0,
      peek: {
        before: 250,
        after: 250
      }
    },
    700: {
      perView: 1,
      gap: 0,
      peek: {
        before: 230,
        after: 230
      }
    },
    650: {
      perView: 1,
      gap: 0,
      peek: {
        before: 180,
        after: 180
      }
    },
    600: {
      perView: 1,
      gap: 0,
      peek: {
        before: 140,
        after: 140
      }
    },
    500: {
      perView: 1,
      gap: 0,
      peek: {
        before: 100,
        after: 100
      }
    },
    414: {
      perView: 1,
      gap: 0,
      peek: {
        before: 80,
        after: 80
      }
    }
  }
}

export const SHOWROOM_STRAP_SLIDER_SETTING = {
  rewind: false,
  perView: 1,
  showNavArrow: true,
  showDots: false,
  animationDuration: 500,
  gap: 0
}
export const HOME_CURATED_CAR_LIST_GLIDE_SETTINGS = {
  rewind: false,
  perView: 8,
  showNavArrow: false,
  showDots: false,
  gap: 12,
  animationDuration: 500,
  breakpoints: {
    1159: {
      perView: 6.4,
      gap: 12
    },
    979: {
      perView: 6.2,
      gap: 12
    },
    839: {
      perView: 5.2,
      gap: 12
    },
    767: {
      perView: 4.9,
      gap: 8
    },
    639: {
      perView: 4.2,
      gap: 8
    },
    414: {
      perView: 2.5,
      gap: 8
    }
  }
}

export const SINGLE_ARCHIVE_SLIDER_SETTINGS = {
  showNavArrow: true,
  showDots: true,
  rewind: false,
  perView: 3,
  gap: 20,
  animationDuration: 500,
  breakpoints: {
    [1239]: {
      perView: 3,
      peek: {
        before: 0,
        after: 50
      }
    },
    [LARGE_WINDOW_WIDTH]: {
      perView: 2,
      peek: {
        before: 0,
        after: 120
      }
    },
    920: {
      perView: 2,
      peek: {
        before: 0,
        after: 100
      }
    },
    860: {
      perView: 2,
      peek: {
        before: 0,
        after: 60
      }
    },
    700: {
      perView: 2,
      peek: {
        before: 0,
        after: 50
      }
    },
    [SMALL_WINDOW_WIDTH]: {
      perView: 1,
      peek: {
        before: 0,
        after: 150
      }
    },
    550: {
      perView: 1,
      peek: {
        before: 0,
        after: 90
      }
    },
    400: {
      gap: 16,
      perView: 1,
      peek: {
        before: 0,
        after: 30
      }
    }
  }
}
export const IMAGE_POST_SLIDER_SETTINGS = {
  showNavArrow: false,
  showDots: true,
  rewind: false,
  perView: 4,
  gap: 20,
  animationDuration: 500,
  peek: {
    before: 0,
    after: 120
  },
  breakpoints: {
    1440: {
      perView: 4,
      peek: {
        before: 0,
        after: 150
      }
    },
    1400: {
      perView: 4,
      peek: {
        before: 0,
        after: 150
      }
    },
    1240: {
      perView: 4,
      peek: {
        before: 0,
        after: 0
      }
    },
    1120: {
      perView: 3,
      peek: {
        before: 0,
        after: 150
      }
    },
    1078: {
      perView: 3,
      peek: {
        before: 0,
        after: 120
      }
    },
    980: {
      perView: 3,
      peek: {
        before: 0,
        after: 80
      }
    },
    860: {
      perView: 2,
      peek: {
        before: 0,
        after: 180
      }
    },
    768: {
      perView: 2,
      peek: {
        before: 0,
        after: 180
      }
    },
    700: {
      perView: 2,
      peek: {
        before: 0,
        after: 160
      }
    },
    670: {
      perView: 2,
      peek: {
        before: 0,
        after: 160
      }
    },
    610: {
      perView: 2,
      peek: {
        before: 0,
        after: 150
      }
    },
    580: {
      perView: 2,
      peek: {
        before: 0,
        after: 120
      }
    },
    550: {
      perView: 2,
      peek: {
        before: 0,
        after: 60
      }
    },
    480: {
      perView: 2,
      peek: {
        before: 0,
        after: 0
      }
    },
    440: {
      perView: 1,
      peek: {
        before: 0,
        after: 190
      }
    },
    400: {
      perView: 1,
      peek: {
        before: 0,
        after: 140
      }
    },
    380: {
      perView: 1,
      peek: {
        before: 0,
        after: 120
      }
    }
  }
}

export const MARKETPLACE_LISTING_CARDS_COMPACT_SETTINGS = {
  showNavArrow: true,
  showDots: true,
  rewind: false,
  perView: 3,
  gap: 16,
  animationDuration: 500,
  peek: {
    before: 5,
    after: 5
  },
  breakpoints: {
    1440: {
      perView: 3,
      peek: {
        before: 5,
        after: 5
      }
    },
    1120: {
      perView: 3,
      peek: {
        before: 5,
        after: 5
      }
    },
    1078: {
      perView: 2,
      peek: {
        before: 5,
        after: 5
      }
    },
    1024: {
      perView: 2,
      peek: {
        before: 5,
        after: 5
      }
    },
    980: {
      perView: 2,
      peek: {
        before: 5,
        after: 5
      }
    },
    900: {
      perView: 2,
      peek: {
        before: 5,
        after: 5
      }
    },
    860: {
      perView: 2,
      peek: {
        before: 5,
        after: 5
      }
    },
    768: {
      perView: 2,
      peek: {
        before: 5,
        after: 160
      }
    },
    700: {
      perView: 2,
      peek: {
        before: 5,
        after: 90
      }
    },
    670: {
      perView: 2,
      peek: {
        before: 5,
        after: 60
      }
    },
    610: {
      perView: 2,
      peek: {
        before: 5,
        after: 5
      }
    },
    580: {
      perView: 1,
      peek: {
        before: 5,
        after: 270
      }
    },
    550: {
      perView: 1,
      peek: {
        before: 5,
        after: 240
      }
    },
    480: {
      perView: 1,
      peek: {
        before: 5,
        after: 170
      }
    },
    440: {
      perView: 1,
      peek: {
        before: 5,
        after: 130
      }
    },
    400: {
      perView: 1,
      peek: {
        before: 5,
        after: 90
      }
    },
    380: {
      perView: 1,
      peek: {
        before: 5,
        after: 74
      }
    },
    360: {
      perView: 1,
      peek: {
        before: 5,
        after: 54
      }
    }
  }
}

export const MARKETPLACE_LISTING_CARDS_MINIMAL_SETTINGS = {
  showNavArrow: true,
  showDots: true,
  rewind: false,
  perView: 3,
  gap: 16,
  animationDuration: 500,
  peek: {
    before: 5,
    after: 5
  },
  breakpoints: {
    1024: {
      perView: 3,
      peek: {
        before: 5,
        after: 0
      }
    },
    767: {
      perView: 2,
      peek: {
        before: 5,
        after: 160
      }
    },
    670: {
      perView: 2,
      peek: {
        before: 5,
        after: 120
      }
    },
    580: {
      perView: 2,
      peek: {
        before: 5,
        after: 100
      }
    },
    550: {
      perView: 2,
      peek: {
        before: 5,
        after: 80
      }
    },
    480: {
      perView: 2,
      peek: {
        before: 5,
        after: 40
      }
    },
    440: {
      perView: 2,
      peek: {
        before: 5,
        after: 10
      }
    },
    400: {
      perView: 1,
      peek: {
        before: 5,
        after: 180
      }
    },
    380: {
      perView: 1,
      peek: {
        before: 5,
        after: 160
      }
    }
  }
}

export const MARKETPLACE_LISTING_CARDS_SETTINGS = {
  showNavArrow: true,
  showDots: true,
  rewind: false,
  perView: 4,
  gap: 16,
  animationDuration: 500,
  peek: {
    before: 0,
    after: 0
  },
  breakpoints: {
    1440: {
      gap: 24,
      perView: 4,
      peek: {
        before: 0,
        after: 0
      }
    },
    1400: {
      gap: 24,
      perView: 4,
      peek: {
        before: 0,
        after: 0
      }
    },
    1240: {
      gap: 24,
      perView: 4,
      peek: {
        before: 0,
        after: 0
      }
    },
    1239: {
      perView: 3,
      peek: {
        before: 0,
        after: 200
      }
    },
    1078: {
      perView: 3,
      peek: {
        before: 0,
        after: 160
      }
    },
    1024: {
      perView: 3,
      peek: {
        before: 0,
        after: 110
      }
    },
    980: {
      perView: 3,
      peek: {
        before: 0,
        after: 60
      }
    },
    900: {
      perView: 3,
      peek: {
        before: 0,
        after: 0
      }
    },
    860: {
      perView: 2,
      peek: {
        before: 0,
        after: 190
      }
    },
    768: {
      perView: 2,
      peek: {
        before: 0,
        after: 120
      }
    },
    700: {
      perView: 2,
      peek: {
        before: 0,
        after: 90
      }
    },
    670: {
      perView: 2,
      peek: {
        before: 0,
        after: 60
      }
    },
    640: {
      perView: 2,
      peek: {
        before: 0,
        after: 0
      }
    },
    580: {
      perView: 1,
      peek: {
        before: 0,
        after: 240
      }
    },
    550: {
      perView: 1,
      peek: {
        before: 0,
        after: 200
      }
    },
    520: {
      perView: 1,
      peek: {
        before: 0,
        after: 180
      }
    },
    480: {
      perView: 1,
      peek: {
        before: 0,
        after: 120
      }
    },
    440: {
      perView: 1,
      peek: {
        before: 0,
        after: 90
      }
    },
    400: {
      perView: 1,
      peek: {
        before: 0,
        after: 60
      }
    },
    360: {
      perView: 1,
      peek: {
        before: 0,
        after: 54
      }
    }
  }
}

export const SLIDER_SETTINGS_VERTICAL_ARTICLES = {
  className: 'vertical',
  vertical: true,
  gap: 0,
  verticalSwiping: true,
  dots: false,
  infinite: false,
  slidesToShow: 3.5,
  slidesToScroll: 1,
  arrows: true,
  speed: 500,
  outline: false,
  responsive: [
    {
      breakpoint: 1239,
      settings: {
        slidesToShow: 3.5
      }
    },
    {
      breakpoint: SMALL_WINDOW_WIDTH,
      settings: {
        slidesToShow: 3.5
      }
    }
  ]
}

export const DCOTY_CATEGORY_SLIDER = {
  showDots: false,
  showNavArrow: false,
  rewind: false,
  gap: 16,
  animationDuration: 500,
  perView: 4,
  breakpoints: {
    920: {
      gap: 40,
      perView: 3,
      peek: {
        before: 0,
        after: 80
      }
    },
    768: {
      gap: 40,
      perView: 2,
      peek: {
        before: 0,
        after: 80
      }
    },
    600: {
      gap: 40,
      perView: 1,
      peek: {
        before: 0,
        after: 80
      }
    }
  }
}

export const IPS_SLIDER_SETTINGS = {
  showNavArrow: true,
  showDots: true,
  rewind: false,
  perView: 1,
  gap: 10,
  animationDuration: 500
}

export const IPS_SLIDER_LIFESTYLE_CARDS_SETTINGS = {
  showNavArrow: true,
  showDots: true,
  rewind: false,
  perView: 4,
  gap: 10,
  animationDuration: 500,
  breakpoints: {
    1240: {
      gap: 40,
      perView: 3,
      peek: {
        before: 0,
        after: 180
      }
    },
    1165: {
      gap: 40,
      perView: 3,
      peek: {
        before: 0,
        after: 130
      }
    },
    1090: {
      gap: 40,
      perView: 3,
      peek: {
        before: 0,
        after: 80
      }
    },
    1030: {
      gap: 40,
      perView: 2,
      peek: {
        before: 0,
        after: 260
      }
    },
    930: {
      gap: 40,
      perView: 2,
      peek: {
        before: 0,
        after: 180
      }
    },
    820: {
      gap: 40,
      perView: 2,
      peek: {
        before: 0,
        after: 120
      }
    },
    750: {
      gap: 40,
      perView: 2,
      peek: {
        before: 0,
        after: 160
      }
    },
    700: {
      gap: 40,
      perView: 2,
      peek: {
        before: 0,
        after: 80
      }
    },
    640: {
      gap: 40,
      perView: 2,
      peek: {
        before: 0,
        after: 0
      }
    },
    560: {
      gap: 40,
      perView: 1,
      peek: {
        before: 0,
        after: 200
      }
    },
    480: {
      gap: 40,
      perView: 1,
      peek: {
        before: 0,
        after: 140
      }
    },
    420: {
      gap: 40,
      perView: 1,
      peek: {
        before: 0,
        after: 90
      }
    }
  }
}

export const NUSED_POPULAR_SEARCHES = {
  showNavArrow: true,
  showDots: false,
  rewind: false,
  perView: 5,
  gap: 24,
  animationDuration: 300,
  breakpoints: {
    2400: {
      perView: 4,
      peek: {
        before: 0,
        after: 230
      }
    },
    1239: {
      perView: 4,
      peek: {
        before: 0,
        after: 200
      }
    },
    1160: {
      perView: 4,
      peek: {
        before: 0,
        after: 100
      }
    },
    1023: {
      perView: 4,
      peek: {
        before: 0,
        after: 0
      }
    },
    980: {
      perView: 3,
      peek: {
        before: 0,
        after: 160
      }
    },
    900: {
      perView: 3,
      peek: {
        before: 0,
        after: 100
      }
    },
    860: {
      perView: 3,
      peek: {
        before: 0,
        after: 50
      }
    },
    768: {
      gap: 24,
      perView: 3,
      peek: {
        before: 0,
        after: 10
      }
    },
    640: {
      gap: 16,
      perView: 2,
      peek: {
        before: 0,
        after: 140
      }
    },
    580: {
      gap: 16,
      perView: 2,
      peek: {
        before: 0,
        after: 125
      }
    },
    480: {
      gap: 16,
      perView: 2,
      peek: {
        before: 0,
        after: 110
      }
    },
    440: {
      gap: 16,
      perView: 2,
      peek: {
        before: 0,
        after: 90
      }
    },
    400: {
      gap: 16,
      perView: 2,
      peek: {
        before: 0,
        after: 70
      }
    },
    380: {
      gap: 16,
      perView: 2,
      peek: {
        before: 0,
        after: 60
      }
    },
    360: {
      gap: 16,
      perView: 2,
      peek: {
        before: 0,
        after: 50
      }
    }
  }
}
